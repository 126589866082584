#variables{

  input[type="text"]{
    width: 90px;
    display: inline-block;
    margin-bottom: 0;
  }

  span{
    display: inline-block;
    padding: 20px 10px;
  }
}

#solar{

  input[type="text"]{
    width: 100%;
    display: inline-block;
  }

  span{
    display: inline-block;
    padding: 6px 0;
  }
}

#optimizer{

  input[type="text"]{
    width: 100%;
    display: inline-block;
  }

  span{
    display: inline-block;
    padding: 20px 10px;
  }
}