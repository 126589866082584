.toast {
  padding: 10px;
  width: 220px;
  position: fixed;
  right: -241px;
  top: 40px;
  text-align: center;
  border-radius: 1px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  color: #fff;
  z-index: 9999;
  background-color: $info-color;

  &.error,
  &.alert {
    background-color: $alert-color;
  }

  &.success {
    background-color: $success-color;
  }

  animation: moveInOut ease-in 5;
  animation-fill-mode: forwards;
  animation-duration: 5s;
  animation-iteration-count: 1;
}

@keyframes moveInOut {
  0% { right: -241px; }
  10% { right: 20px; }
  90% { right: 20px; }
  100% { right: -241px; }
}