#login{
  height: 100%;

  #login_container{
    margin: 0 auto;
    width: 90%;
    position: relative;
    top: 50%;
    transform: translateY(-50%) translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateY(-50%) translateZ(0) scale(1.0, 1.0);
    -webkit-font-smoothing: antialiased !important;

    @include breakpoint(medium) {
      width: 400px;
    }

    .benh-logo{
      margin:0 auto 30px;
      width: 100%;
      height: 186px;
      background: url("../../../images/benh-logo-large.png") center center no-repeat;
      background-size: 100%;

      @include breakpoint(medium) {
        width: 400px;
        margin:0 auto 25%;
      }
    }

    /* INPUTS */
    .login-input-group{
      position:relative;
      margin-bottom:45px;

      label{
        position: absolute;
        font-weight: 300;
        pointer-events: none;
        top: 10px;
        color: #606050;
        font-size: 16px;
        padding-left: 20px;
        opacity: .5;
        transition: all 0.2s ease-out;
      }

      input{
        font-size: 18px;
        color: #606050;
        background-color: transparent;
        border: 0;
        padding: 0 20px;
        margin-bottom: 5px;
        box-shadow: none;

        /* active state */
        &:focus ~ label, &:valid ~ label        {
          top:-20px;
          font-size:13px;
          text-transform: uppercase;
        }

        &:focus ~ .input-bar, &:focus ~ .input-bar{
          opacity: 1;
        }
      }

      .input-bar{
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background-color: $secondary-color;
        opacity: .5;
        transition: all 0.2s ease-out;
      }
    }
    
    .remember-me{
      margin: 10px 0 10px;
      padding: 0;

      @include breakpoint(medium) {
        margin: 60px 0 20px;
        padding: 0 10px;
      }
    }

    #login-button{
      width: 100%;
      height: 60px;
      margin: 0 auto 15px;
      border-radius: 30px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      border: 0;
      background-color: white;
      color: $secondary-color;
      cursor: pointer;
      box-shadow: 0 0 12.75px 2.25px rgba(0, 0, 0, 0.08);
      transition: all 0.2s ease-out;

      &:hover{
        box-shadow: 0 0 12.75px 2.25px rgba(0, 0, 0, 0.15);
      }
    }
    
    .forgot-password{
      color: $secondary-color;
      text-align: center;
      opacity: .65;
      font-size: 14px;
      padding: 10px 0;
      text-transform: uppercase;
      line-height: 32px;
      display: block;
      transition: all 0.2s ease-out;

      &:hover{
        opacity: 1;
      }
    }
    
    .errors{
      margin-bottom: 40px;
    }
  }
}