.pagination-holder{
  margin: 20px 0;
  text-align: center;

  &:hover a{
    opacity: 1 !important;
  }

  div{
    display: inline-block;

    a{
      padding: 5px 10px;
      border: 1px solid rgba(196, 164, 105, 0.25);
      border-radius: $global-radius;
      color: $secondary-color;
      transition: all .2s ease-out;

      &:hover{
        border: 1px solid $secondary-color;
      }

      &.active{
        border: 1px solid $secondary-color;
        color: white;
        background-color: $secondary-color;
      }
    }

    &:first-child, &:last-child{
      a{
        opacity: .5;
        border: 0;
        font-size: .8rem;
        text-transform: uppercase;
      }
    }
  }
}