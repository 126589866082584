table{
  width: 100%;

  thead{

    th{
      padding: 18px 20px;
      text-align: left;
      font-size: 15px;
      color: #999;
      font-weight: 700;
      border-bottom: 2px solid #d7d8cb;
    }
  }

  tbody{

    tr{

      td{
        padding: 18px 20px;
        text-align: left;
        font-size: 18px;
        color: $secondary-color;
      }

      &.facade-row{
        border-top: 1px solid $light-gray;

        &:last-of-type{
          margin-bottom: 20px;
        }

        td{
          padding: 5px 10px;
          text-align: center;
          font-size: 16px;
          color: $dark-gray;
        }
      }
    }
  }

  tfoot{
    border-top: 2px solid #d7d8cb;

    tr{

      td{
        padding: 18px 20px;
      }
    }
  }

  i.fi-foundation{
    font-size: 26px;
    line-height: 16px;
  }
}