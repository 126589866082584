#app{
  padding: 10px;
  @include breakpoint(medium) {
    padding: 30px;
  }
  @include breakpoint(large) {
    padding: 50px;
  }

  #app_holder{
    background-color: white;
    box-shadow: 0 0 12.75px 2.25px rgba(0, 0, 0, 0.15);
    padding: 0 10px 15px;
    height: 100%;

    @include breakpoint(medium) {
      padding: 0 25px 25px;
    }
    @include breakpoint(large) {
      padding: 0 30px 30px;
    }
  }

  #header{
    width: 100%;
    height: 100px;
    background: #fff;
    border-bottom: 1px solid #d7d8cb;
    padding: 25px 0;
    line-height: 50px;
    margin-bottom: 35px;

    .logo-link{
      display: inline-block;
      vertical-align: top;
      height: 50px;
      margin-right: 10px;

      .benh-logo-menu{
        display: inline-block;
        height: 50px;
        width: 50px;
        background: url("../../../images/benh-logo-menubar.png") center center no-repeat;
        background-size: contain;

        @include breakpoint(large) {
          height: 50px;
          width: 123px;
          background: url("../../../images/benh-logo-menubar-large.png") center center no-repeat;
          background-size: contain;
        }
      }
    }

    .menu{
      display: inline-block;
      vertical-align: top;

      .menu-item{
  
        a{
          padding: 0 30px;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          color: rgb(96, 96, 80);
          text-transform: uppercase;
          display: block;
          border-radius: 30px;
          background: #fff;
          transition: all .5s ease-out;
  
          &.active{
            color: $primary-color;
          }
  
          &:hover{
            background: #f2f2f2;
            transition: all .2s ease-out;
          }
        }
      }
    }

    .user{
      text-align: right;

      .menu-user-icon{
        display: inline-block;
        vertical-align: top;
        width: 32px;
        margin-right: 8px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: rgb(96, 96, 80);
        border-radius: 16px;
        background: #fff;
        transition: all .5s ease-out;

        @include breakpoint(large) {
          width: 50px;
          margin-right: 10px;
          font-size: 22px;
          border-radius: 30px;
        }

        &.active{
          color: $primary-color;
        }

        &:hover{
          background: #f2f2f2;
          transition: all .2s ease-out;
        }
      }

      .splitter{
        display: none;
        position: relative;
        top: 5px;
        margin: 0 10px 0 0;
        background-color: rgb(140, 144, 107);
        width: 3px;
        height: 40px;
        border-radius: 2px;

        @include breakpoint(large) {
          display: inline-block;
        }
      }

      .user-details{
        display: inline-block;
        position: relative;
        top: 4px;
        padding-left: 30px;

        .name{
          line-height: 24px;
          font-size: 24px;
          font-weight: 600;
          color: #606050;
        }

        .company{
          line-height: 18px;
          font-size: 18px;
          font-weight: 300;
          color: #8c906b;
        }
      }
    }
  }

  /* MOBILE NAVIGATION */
  #mobile_menu{
    text-align: center;

    a{
      display: block;

      i.fi-list{
        font-size: 2rem;
      }
    }
  }

  #mobile_menu {

    &:hover > ul{
      display: block;
    }

    ul {
      position: absolute;
      left: 0;
      right: 0;
      height: auto;
      display: none;
      list-style: none;
      text-align: left;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
      z-index: 100;

      li{
        background-color: $secondary-color;
        width: 100%;
        float: none;
        border-bottom: 1px solid $white;

        a{
          color: $white;
          display: block;
          padding: 18px 20px;
        }

        &.logout{
          background-color: #ab4b47;
        }
        
        &.mobile-menu-user{
          text-align: right;
          background-color: white;
          padding: 12px 20px;

          .name{
            line-height: 28px;
            font-size: 24px;
            font-weight: 600;
            color: #606050;
          }

          .company{
            line-height: 21px;
            font-size: 18px;
            font-weight: 300;
            color: #8c906b;
          }
        }
      }
    }
  }
}