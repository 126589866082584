.edit-group{

  &:not(.active) .active-on-edit,
  &.active .active-on-view{
    display: none !important;
  }

  &:not(:last-child){
    border-bottom: 1px solid #eee;
  }

  .edit-field-holder{
    line-height: 38px;

    a{
      display: inline-block;
      width: 38px;
      height: 38px;
      text-indent: 0;
      margin: 0 0 0 20px;
      text-align: center;
      transition: .1s all ease-out;
      border-radius: 50%;
      opacity: .35;

      &:hover{
        background-color: $light-gray;
        opacity: 1;
      }

      i{
      }
    }
  }
}