/* BASICS */
html {
  height: 100%;
  margin: 0;
  padding: 0;

  background: url("../../../images/benh-login-background.jpg") center center no-repeat fixed;
  background-size: cover;
}

body {
  height: 100%;
  background-color: transparent;
  overflow: scroll;
  margin: 0;
  padding: 0;
}

.right{
  float: right;
}

.no-margin{
  margin: 0;
}

.mt-10{
  margin-top: 10px;
}

.mt-20{
  margin-top: 20px;
}

.mt-50{
  margin-top: 50px;
}

.padding-0{
  padding: 0 !important;
}

.rounded{
  border-radius: 500px;
}

.radius{
  border-radius: $global-radius;
}

.button{
  text-transform: uppercase;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;

  &.bigger-icon{

    i{
        font-size: 1.5rem;
    }
  }
}

.buttons-end-of-form{
  margin: 25px 0 0 0;
  padding: 25px 0 0 0;
  border-top: 1px solid $light-gray;
}

/* ICON HOLDER STYLING */
.icon-holder{
  padding: 13px 0;
  text-align: right !important;

  a{
    display: inline-block;
    width: 38px;
    height: 38px;
    text-indent: 0;
    margin: 0;
    text-align: center;
    transition: .1s all ease-out;
    border-radius: 50%;

    &:hover{
      background-color: $light-gray;
    }

    i{
      line-height: 38px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      
      &.fi-x{
        color: #d43c3c;
      }
    }

    &:not(.default-solar-panel){

      .fi-star{
        color: $light-gray;
      }
    }
  }
}
/* */

#content-container{

  h1{
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #333;
  }

  h2{
    font-weight: 300;
    margin: 20px 0;
    color: $secondary-color;
  }

  h3{
    font-weight: 300;
    color: $secondary-color;
  }
  
  label{
    cursor: pointer;
    
    span{
      padding-left: 10px;
      color: $secondary-color;
      font-weight: 300;
      font-size: 16px;
    }
  }

  /* INFO ICON */
  h1,
  h2,
  h3,
  label span,
  .data-label{
    .has-tip{
      font-size: 1.3rem;
      margin: 0;
      border: 0;
      color: $warning-color;
      line-height: 25px;
    }
  }

  .inline-text{
    padding: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input-field-label{
    padding: 39px 0 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .in-between-border{
    margin-bottom: 20px;

    &:not(:last-child){
      padding-bottom: 20px;
      border-bottom: 1px solid $medium-gray;
    }
  }
  
  input[type="number"],
  input[type="text"],
  select{
    height: 46px;
  }

  /* RADIO BUTTON STYLING */
  .radio-choice{

    label{
      display: inline-block;
      padding: 8px 15px 0 10px;
      background-color: $white;
      border-radius: $global-radius;
      border: 1px solid $medium-gray;
      margin-bottom: 10px;
      overflow: hidden;
      user-select: none;
    }

    input[type="radio"]:checked + span{
      color: $primary-color;
      position: relative;
      z-index: 10;
    }
    input[type="radio"]:checked + span:before{
      content: " ";
      position: absolute;
      height: 46px;
      width: 500px;
      top: -13px;
      right: 0;
      bottom: 0;
      left: -35px;
      background-color: $primary-color;
      opacity: .2;
      z-index: 5;
      transition: all .1s ease-out;
    }

    span{
      color: $medium-gray;
      margin: 0;
      padding: 0;
    }
  }
  
  /* HIGHLIGHTED BLOCK */
  .highlighted{
    background-color: #fff8e7;
    padding: 11px 10px 2px;
    border-radius: $global-radius;
    margin-bottom: 20px;

    .highlighted{
      background-color: #faf3dd;
    }
  }
  
  /* DATA STYLING */
  .data-label{
    text-transform: uppercase;
    color: $medium-gray;
    font-size: .9rem;
    font-weight: 600;

    &.incomplete{
      border-left: 3px solid $alert-color;
    }
  }
  
  .data-result{
    padding: 2px 10px 12px 25px;
  }
  
  /* NO DATA TO SHOW */
  .no-data{
    text-align: center;
    font-style: italic;
    color: $medium-gray;
  }
}

/* CALCULATION */

.total-price-row{
  padding: 6px 0;
  
  &:not(:last-of-type){
    border-bottom: 1px solid $light-gray;
  }
}

.total-price-total{
  padding: 6px 0;
  border-top: 1px solid $dark-gray;
}

a, input{

  &.button{
    &.success{
      color: white;
    }

    &.success,
    &.alert{
      color: white !important;

      span{
        color: white !important;
      }
    }

    i{
      color: white !important;
    }
  }
}

table{
  border-collapse: collapse;

  tbody{

    tr{

      &:not(:last-child){
        border-bottom: 1px solid $light-gray;
      }
    }
  }
}